import {
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Stack, Text, Button } from "@mantine/core";
import * as React from "react";

import { initializeLiveUpdate } from "./liveUpdate";

export const AppLiveUpdate = ({ store }) => {
  return store.willUpdate ? <IonModal isOpen backdropDismiss={false} style={{ "--border-radius": "20px" }} initialBreakpoint={0.3}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Dostępna aktualizacja</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <Stack>
          <Text ta="center">Dostępna jest najnowsza wersja aplikacji</Text>
          <Button size="md" onClick={() => {
            initializeLiveUpdate();
          }} loading={store.isLiveUpdateLoading}><Text>{store.isLiveUpdateLoading ? "Instalowanie..." : "Zainstaluj"}</Text></Button>
        </Stack>
      </IonContent>
    </IonModal> : null
};
