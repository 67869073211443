import {
  IonContent,
  IonFooter,
  IonHeader,
  IonToolbar,
  useIonLoading,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import { ActionIcon, Divider, Group, Text, Title } from "@mantine/core";
import * as Sentry from "@sentry/capacitor";
import { IconChevronLeft } from "@tabler/icons-react";
import { Button } from "@zozia/ui";
import * as React from "react";
import { useHistory } from "react-router-dom";

import { AppPage } from "../../../components/AppPage";
import { SignInWithApple } from "../components/SignInWithApple";
import { SignInWithGoogle } from "../components/SignInWithGoogle2";
import {
  loginWithApple,
  loginWithGoogle,
  login as loginWithKeycloak,
  logout,
} from "../utils/auth";

export const LoginPage = () => {
  const router = useIonRouter();
  const history = useHistory();
  const [present] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();

  const handleLogin = async ({ login }) => {
    try {
      await Promise.all([presentLoading(), login()]);

      await present({
        message: "Zalogowano pomyślnie",
        duration: 2000,
        position: "top",
      });
      history.replace(`/tabs/discovery`);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      router.push("/tabs/login");
      await Promise.all([
        logout(),
        present({
          message: "Wystąpił błąd podczas logowania",
          duration: 3000,
          position: "top",
        }),
      ]);
    } finally {
      await dismissLoading();
    }
  };

  const handleKeycloakLogin = async () => {
    await handleLogin({ login: loginWithKeycloak });
  };

  const handleAppleSignIn = async () => {
    await handleLogin({ login: loginWithApple });
  };

  const handleGoogleSignIn = async () => {
    await handleLogin({ login: loginWithGoogle });
  };

  return (
    <AppPage title="Logowanie">
      <IonContent className="ion-padding" fullscreen>
        <IonHeader>
          <IonToolbar>
            <ActionIcon
              variant="transparent"
              className="-ml-2 rounded-full"
              c="black"
              onClick={() => {
                router.push("/tabs", "back");
              }}
            >
              <IconChevronLeft />
            </ActionIcon>
            <Title className="text-4xl font-extrabold">
              Witamy w aplikacji Zozia
            </Title>
            <Text className="font-small text-lg" c="gray">
              Zaloguj się, aby móc korzystać z aplikacji
            </Text>
          </IonToolbar>
        </IonHeader>
        <Group className="my-8" justify="center">
          <Button
            onClick={handleKeycloakLogin}
            className="text-xl h-[48px] w-[375px]"
            size="lg"
          >
            Zaloguj się
          </Button>
        </Group>
        <Divider label="ALBO" />
        <Group className="mt-8" justify="center">
          <SignInWithApple onClick={handleAppleSignIn} />
          <SignInWithGoogle onClick={handleGoogleSignIn} />
        </Group>
        <IonFooter>
          <Divider />
          <Text c="gray" fz="sm" className="my-4 px-2 text-center">
            Zozia wykorzystuje Twoje dane tylko do składania zamówień. Nie
            będziemy Ci wysyłać niechcianych wiadomości e-mail ani wysyłać
            wiadomości SMS.
          </Text>
        </IonFooter>
      </IonContent>
    </AppPage>
  );
};
