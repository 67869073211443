import { IonContent, IonItem, IonLabel, IonList } from "@ionic/react";
import { Group, LoadingOverlay, Stack, Text, Title } from "@mantine/core";
import {
  IconBuildingStore,
  IconCoins,
  IconCreditCard,
  IconInfoCircle,
  IconMapPin,
  IconNumber,
  IconPackage,
  IconTruckDelivery,
  IconUser,
} from "@tabler/icons-react";
import { tryToGetMostAccurateAddress } from "@zozia/address";
import { IconApplePay, IconBlikPay } from "@zozia/icons";
import { Money } from "@zozia/money";
import { Price } from "@zozia/ui";
import * as React from "react";
import { useParams } from "react-router-dom";

import { AppPage } from "../../../components/AppPage";
import {
  LocationPaymentMethodType,
  useOrderQuery,
} from "../../graphql/graphql";
import { Section } from "../../session/components/Section";
import { useUser } from "../../session/useUser";
import { EstimatedDeliveryTimeSection } from "../components/EstimatedDeliveryTimeSection";
import { OrderStatusIcon } from "../components/OrderStatusIcon";
import { OrderedItem } from "../components/OrderedItem";
import { translatePaymentStatus } from "../components/helpers";

const mapValues = <T extends Record<string, unknown>>(
  values: T,
  callback: (val: T) => unknown,
) => (values ? callback(values) : {});

export const OrderPage = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const [user] = useUser();

  const { data, isLoading, refetch } = useOrderQuery(
    {
      id: orderId,
    },
    {
      enabled: !!orderId,
      refetchInterval: 5000,
    },
  );

  React.useEffect(() => {
    refetch();
  }, []);

  const order = mapValues(data?.order, (val) => {
    return {
      ...val,
      id: val.id.slice(-4),
    };
  });

  const isLoadingOrder = isLoading;

  const itemsPrice = order?.items?.reduce((acc, item) => {
    return Money.reduce(acc, Money.fromFactionlessAndQuantity(item.offer.price, item.offer.quantity, (item.offer.currency || "PLN").toUpperCase()));
  }, Money.zero("PLN")
  ) || Money.zero("PLN");

  const totalSum = Money.reduce(itemsPrice, Money.fromFractionlessAmount(order?.deliveryOption?.deliveryPrice, (order?.deliveryOption?.currency || "PLN").toUpperCase()), Money.fromFractionlessAmount(order?.packingOption?.packingPrice, (order?.packingOption?.currency || "PLN").toUpperCase()));

  return (
    <AppPage title={data && `Zamówienie ${order.id}`}>
      <IonContent className="ion-padding">
        {isLoadingOrder ? (
          <LoadingOverlay visible={isLoadingOrder} />
        ) : (
          <div style={{ marginTop: "var(--ion-safe-area-top)" }}>
            {data && (
              <Group justify="space-between" align="baseline">
                <Title>
                  Zamówienie <span className="uppercase">{order.id}</span>
                </Title>
                <OrderStatusIcon status={order.status} />
              </Group>
            )}
            <EstimatedDeliveryTimeSection
              orderStatus={order.status}
              lastUpdated={order?.trackDelivery?.updatedAt}
              estimatedDeliveryTime={
                order?.trackDelivery?.estimatedDeliveryTime
              }
              deliveryOptionType={order.deliveryOption.type}
            />
            <Section title="Dane zamawiającego" inset>
              <IonList>
                <IonItem lines="none">
                  <IconUser slot="start" />
                  <IonLabel>
                    {user?.name ? user?.name : `${user?.givenName} ${user?.familyName}`}
                    <Text>
                      {user?.phoneNumber 
                        ? user.phoneNumber
                        : "Brak numeru telefonu"}
                    </Text>
                  </IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IconMapPin slot="start" />
                  <IonLabel>
                    {
                      tryToGetMostAccurateAddress(order.deliveryAddress.address)
                        .primary
                    }
                    <Text>
                      {
                        tryToGetMostAccurateAddress(
                          order.deliveryAddress.address,
                        ).secondary
                      }
                    </Text>
                  </IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IconInfoCircle slot="start" />
                  <IonLabel>
                    <h3>Dodatkowe informacje</h3>
                    <p>{order.deliveryAddress.address.additionalInformation ? order.deliveryAddress.address.additionalInformation : "Brak"}</p>
                  </IonLabel>
                </IonItem>
              </IonList>
            </Section>
            <Section title="Płatność" inset>
              <IonList>
                <IonItem lines="none">
                  {getIcon(order.paymentMethod.type)}
                  <IonLabel>
                    <h3>Metoda płatności</h3>
                    <p>{order.paymentMethod.name}</p>
                  </IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IconInfoCircle slot="start" />
                  <IonLabel>
                    <h3>Status</h3>
                    <p>{translatePaymentStatus(order.paymentStatus)}</p>
                  </IonLabel>
                </IonItem>
              </IonList>
            </Section>
            <Section title="Informacje dodatkowe" inset>
              <IonList>
                <IonItem lines="none">
                  <IconNumber slot="start" />
                  <IonLabel>
                    <h3>Numer zamówienia</h3>
                    <Text className="uppercase">{order.id}</Text>
                  </IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IconPackage slot="start" />
                  <IonLabel>
                    <h3>Metoda pakowania</h3>
                    <Text>{order.packingOption.name}</Text>
                    <Text className="ion-text-wrap">
                      {order.packingOption.description}
                    </Text>
                  </IonLabel>
                  <Price
                    value={Money.fromFractionlessAmount(
                      order.packingOption.packingPrice,
                      (order.packingOption.currency || "PLN").toUpperCase(),
                    ).toNumber()}
                  />
                </IonItem>
                <IonItem lines="none">
                  <IconTruckDelivery slot="start" />
                  <IonLabel>
                    <h3>Dostawa</h3>
                    <p>{order.deliveryOption.description}</p>
                  </IonLabel>
                  <Price
                    value={Money.fromFractionlessAmount(
                      order.deliveryOption.deliveryPrice,
                      (order.deliveryOption.currency || "PLN").toUpperCase(),
                    ).toNumber()}
                  />
                </IonItem>
              </IonList>
            </Section>
            <Section title="Dane lokalizacji" inset>
              <IonList>
                <IonItem lines="none">
                  <IconBuildingStore slot="start" />
                  <IonLabel>
                    <h3>Nazwa</h3>
                    <p>{order.location.name}</p>
                    <h3>Adres</h3>
                    <p>{order.location.address}</p>
                  </IonLabel>
                </IonItem>
              </IonList>
            </Section>
            <Section title="Zamówione produkty" inset>
              <Stack gap={8}>
                {order.items.map((item) => (
                  <OrderedItem key={item.id} item={item} />
                ))}
              </Stack>
            </Section>
            <Section title="Podsumowanie" inset className="mb-12">
              <IonList>
                {order.items.map((item) => (
                  <IonItem key={item.id} lines="none">
                    <IonLabel>
                      <h3>{item.offer.product.name}</h3>
                      <Text>
                        {item.offer.quantity} szt. x{" "}
                        <Price
                          value={Money.fromFractionlessAmount(
                            item.offer.price,
                            (item.offer.currency || "PLN").toUpperCase(),
                          ).toNumber()}
                        />
                      </Text>
                    </IonLabel>
                    <Price
                      value={Money.fromFractionlessAmount(
                        item.offer.price * item.offer.quantity,
                        (item.offer.currency || "PLN").toUpperCase(),
                      ).toNumber()}
                    />
                  </IonItem>
                ))}
                <IonItem lines="none">
                  <IonLabel>
                    <h3>{order.deliveryOption.description}</h3>
                  </IonLabel>
                  <Price
                    value={Money.fromFractionlessAmount(
                      order.deliveryOption.deliveryPrice,
                      (order.deliveryOption.currency || "PLN").toUpperCase(),
                    ).toNumber()}
                  />
                  </IonItem>
                  <IonItem lines="none">
                  <IonLabel>
                    <h3>{order.packingOption.name}</h3>
                  </IonLabel>
                  <Price
                    value={Money.fromFractionlessAmount(
                      order.packingOption.packingPrice,
                      (order.packingOption.currency || "PLN").toUpperCase(),
                    ).toNumber()}
                  />
                </IonItem>
                <IonItem lines="none">
                  <IonLabel>
                    <h3>Suma</h3>
                  </IonLabel>
                  <Price
                    value={totalSum.toNumber()}
                  />
                </IonItem>
              </IonList>
              </Section>
          </div>
        )}
      </IonContent>
    </AppPage>
  );
};

const getIcon = (type: LocationPaymentMethodType) => {
  switch (type) {
    case "ApplePay":
      return <IconApplePay width={40} height={26} slot="start" />;
    case "Blik":
      return <IconBlikPay width={40} slot="start" />;
    case "Card":
    case "Online":
      return <IconCreditCard slot="start" />;
    case "Cash":
      return <IconCoins slot="start" />;
    default:
      return <IconCoins slot="start" />;
  }
};
