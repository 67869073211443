import * as React from "react";
import { useObservableState } from "observable-hooks";
import { useTimeout } from "@mantine/hooks";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Alert, Button, Stack, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

import { useCart } from "../../../../cart/CartContext";
import { location$ } from "../../../../geolocation/epics";
import { RouteBackModal } from "../../../../../components/RouteBackModal";
import { DeliveryMap } from "./DeliveryMap";
import { useMyAddressesQuery } from "../../../../graphql/graphql";
import { Distance } from "../../../utils/geo-distance";
import { ModalAsRoute } from "../../../../discovery/components/ModalAsRoute";
import { DeliveryAddressAdditionalInformationModal } from "./DeliveryAddressAdditionalInformationModal";
import { useRedirectQuerySearch } from "../../../../../hooks/useRedirectQuerySearch";
import { usePageContext } from "../../../../../components/PageContext";
import { tryToGetMostAccurateAddress } from "@zozia/address";
import { isPlatform } from "@ionic/react";

export const SetDeliveryAddress = ({
  prevMatch,
  opened,
  removeNestedOutlet,
  open,
}) => {
  const { setData } = usePageContext();
  const match = useRouteMatch();
  const { toggleCartButton } = useCart();
  const modal = React.useRef<HTMLIonModalElement>(null);
  const currentLocation = useObservableState(location$, null);
  const history = useHistory();
  const { redirectUri } = useRedirectQuerySearch();

  const [lastUserDefinedPosition, setLastUserDefinedPosition] =
    React.useState();
  const [mapLoaded, setMapLoaded] = React.useState(false);
  const [userForcedPosition, setUserForcedPosition] = React.useState(null);
  const { data } = useMyAddressesQuery({
    type: "DeliveryAddress",
  });
  const [address, setAddress] = React.useState(null);

  React.useEffect(() => {
    if (currentLocation || userForcedPosition) {
      const latitude = userForcedPosition?.lat || currentLocation.latitude;
      const longitude = userForcedPosition?.lng || currentLocation.longitude;
      fetch(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`,
      )
        .then((res) => res.json())
        .then((data) => {
          const { address: posibleAddress } = data;

          const accurateAddress = tryToGetMostAccurateAddress(posibleAddress);
          setAddress(accurateAddress);
        });
    }
  }, [currentLocation, userForcedPosition]);

  React.useEffect(() => {
    if (data && data.myAddresses.length > 0) {
      const values = data.myAddresses[0].address;
      setLastUserDefinedPosition(values);
    }
  }, [data]);

  React.useEffect(() => {
    toggleCartButton();
    return () => {
      toggleCartButton();
    };
  }, []);

  useTimeout(
    () => {
      setMapLoaded(true);
    },
    100,
    { autoInvoke: true },
  );

  const { distance: distanceBetweenCurrentAndLastSavedLocation, unit } =
    (lastUserDefinedPosition &&
      currentLocation &&
      Distance.between(
        {
          lat: lastUserDefinedPosition.latitude,
          lng: lastUserDefinedPosition.longitude,
        },
        { lat: currentLocation.latitude, lng: currentLocation.longitude },
      ).human_readable()) ||
    {};

  const canTreatingAsSameLocation =
    distanceBetweenCurrentAndLastSavedLocation < 4 && unit === "m";

  const lastSavedAddress =
    lastUserDefinedPosition &&
    tryToGetMostAccurateAddress(lastUserDefinedPosition);

  return (
    <RouteBackModal
      prevMatch={prevMatch}
      ref={modal}
      opened={opened}
      open={open}
      removeNestedOutlet={removeNestedOutlet}
      title="Adres dostawy"
    >
      {!canTreatingAsSameLocation && lastSavedAddress ? (
        <Alert
          variant="light"
          color="yellow"
          icon={<IconAlertCircle />}
          className="mx-2 mb-2"
        >
          Wygląda na to, że jesteś w innym miejscu niż ostatnio. Zapisana
          lokalizacja to{" "}
          <b>
            {lastSavedAddress.primary} {lastSavedAddress.secondary}
          </b>
        </Alert>
      ) : null}
      {currentLocation && mapLoaded ? (
        <DeliveryMap
          zoomOptions={{
            zoomControl: !isPlatform("hybrid"),
          }}
          position={currentLocation}
          onDragEnd={(latLng) => {
            setUserForcedPosition(latLng);
            setData({ userForcedPosition: latLng });
          }}
        />
      ) : null}
      {address ? (
        <div className="ion-padding">
          <Stack align="center" gap={2}>
            <Text fw={600} size="lg">
              {address.primary}
            </Text>
            <Text>{address.secondary}</Text>
            <Button
              fullWidth
              mt={10}
              onClick={() => {
                if (currentLocation || userForcedPosition) {
                  const lat =
                    userForcedPosition?.lat || currentLocation.latitude;
                  const lng =
                    userForcedPosition?.lng || currentLocation.longitude;
                  setData({
                    userForcedPosition: {
                      lat,
                      lng,
                    },
                  });
                }
                history.push(
                  `${match.url}/additionalInfo${
                    redirectUri ? `?redirect=${redirectUri}` : ""
                  }`,
                );
              }}
            >
              Dalej
            </Button>
          </Stack>
        </div>
      ) : null}
      <ModalAsRoute
        prevMatch={match}
        routes={{
          [`${match.url}/additionalInfo`]: [
            DeliveryAddressAdditionalInformationModal,
            {modal}
          ]
        }}
      />
    </RouteBackModal>
  );
};
