import { MantineSize } from "@mantine/core";
import { Img } from "@zozia/ui";
import * as React from "react";

type AutoAlignImageProps = {
  src: string;
  alt?: string;
  className?: string;
  onLoaded?: () => void;
  radius?: MantineSize | "none";
  placeholder?: JSX.Element;
  animation?: React.ElementType;
  style?: React.CSSProperties;
  rounded?: "full";
  blurhash?: string;
  skeletonProps?: {
    className?: string;
  };
  srcSet?: string;
  sizes?: string;
};

export const AutoAlignImage = ({
  src,
  alt,
  className,
  onLoaded,
  radius = "xl",
  animation,
  style = {},
  placeholder,
  rounded,
  blurhash,
  skeletonProps,
  srcSet,
  sizes
}: AutoAlignImageProps) => {
  const borderRadius = (() => {
    switch (rounded) {
      case "full":
        return { borderRadius: "9999px" };
      default:
        return { borderRadius: "0" };
    }
  })();

  return (
    <Img
      radius={radius}
      animation={animation}
      onLoaded={onLoaded}
      alt={alt}
      src={src}
      style={{ width: "100%", height: "100%", ...style }}
      className={className}
      blurhash={blurhash}
      placeholder={placeholder}
      borderRadius={borderRadius}
      skeletonProps={skeletonProps}
      srcSet={srcSet}
      sizes={sizes}
    />
  );
};
