import { IonContent, IonHeader } from "@ionic/react";
import {
  ActionIcon,
  Avatar,
  Flex,
  Skeleton,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconChevronDown,
  IconChevronRight,
  IconChevronUp,
  IconUserCircle,
} from "@tabler/icons-react";
import { useTranslation } from "@zozia/react-i18n";
import { noop, range } from "@zozia/std";
import { useDebouncedHandler } from "@zozia/ui";
import { useObservableState } from "observable-hooks";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useHistory } from "react-router-dom";

import { AppPage } from "../../../components/AppPage";
import { Snap } from "../../../components/Snap";
import { SnapItem2 } from "../../../components/SnapItem";
import {
  geolocationPermissionStatus$,
  location$,
} from "../../geolocation/epics";
import {
  useCityListQuery,
  useDiscoveryLocationQuery,
} from "../../graphql/graphql";
import { useSession } from "../../session";
import { StoryCircle } from "../../stories/components/StoryCircle";
import { DiscoveryLocationCard } from "./DiscoveryLocationCard";
import { Error } from "./Error";
import { Searchbar } from "./Searchbar";
import { SetAutomaticMyLocation } from "./SetAutomaticMyLocation/SetAutomaticMyLocation";
import { ShowCityListModal } from "./ShowCityListModal";

const CurrentLocationWithoutPermission = ({ onAreaSelect }) => {
  const [opened, { open, close }] = useDisclosure();
  const { t } = useTranslation("common");
  const currentLocation = useObservableState(location$);
  const geolocationPermissionStatus2 = useObservableState(
    geolocationPermissionStatus$,
    "checking",
  );
  const [selectedName, setSelectedName] = React.useState(null);

  const { data, isLoading } = useCityListQuery(
    {
      input: {
        lat: currentLocation?.latitude,
        lng: currentLocation?.longitude,
      },
    },
    {
      enabled: geolocationPermissionStatus2 !== "checking",
      refetchOnReconnect: false,
      select: ({ cityList }) => {
        const haveDistance = cityList?.[0]?.distance;
        return {
          cityList: haveDistance
            ? cityList.sort((a, b) => a.distance - b.distance)
            : cityList,
          nearestCity: cityList?.[0],
        };
      },
    },
  );

  const name = selectedName ? selectedName : data?.nearestCity?.name;

  const debouncedAreaSelect = useDebouncedHandler(onAreaSelect, 1000);

  React.useEffect(() => {
    if (data?.nearestCity) {
      const location = {
        latitude: data.nearestCity.lat,
        longitude: data.nearestCity.lng,
      };
      debouncedAreaSelect(location);
    }
  }, [data]);

  React.useEffect(() => {
    if (currentLocation) {
      const location = {
        latitude: currentLocation.latitude,
        longitude: currentLocation.longitude,
      };
      debouncedAreaSelect(location);
    }
  }, [currentLocation]);

  return (
    <>
      <Flex direction="column" gap={4} onClick={isLoading ? noop : open}>
        <Flex gap={4}>
          <Text span fw="bold">
            {isLoading ? (
              <Skeleton width="150px" height={21} animate={false} />
            ) : (
              name
            )}
          </Text>
          {opened ? <IconChevronUp /> : <IconChevronDown />}
        </Flex>
      </Flex>
      <SetAutomaticMyLocation onButtonPress={open} />
      <ShowCityListModal
        onAreaSelect={({ latitude, longitude, name }) => {
          onAreaSelect({ latitude, longitude });
          setSelectedName(name);
        }}
        setSelectedName={setSelectedName}
        isDenied={geolocationPermissionStatus2 === "denied"}
        cityList={data?.cityList ?? []}
        isOpen={opened}
        onClose={close}
      />
    </>
  );
};

const DiscoveryToolbar = ({ onAreaSelect }) => {
  return (
    <Flex>
      <CurrentLocationWithoutPermission onAreaSelect={onAreaSelect} />
    </Flex>
  );
};

const Profile = () => {
  const history = useHistory();
  const { snapshot } = useSession();

  const user = snapshot?.user;
  return (
    <Flex className="mr-2">
      <ActionIcon
        variant="transparent"
        size="lg"
        onClick={() => {
          history.push(snapshot ? "/tabs/profile" : "/tabs/login", "forward");
        }}
      >
        {!user ? (
          <IconUserCircle
            color="#3b82f6"
            style={{ width: "100%", height: "100%" }}
          />
        ) : user?.picture ? (
          <StoryCircle>
            <Avatar src={user?.picture} alt={user?.name} />
          </StoryCircle>
        ) : (
          <Avatar>
            {[user?.givenName, user?.familyName]
              .filter(Boolean)
              .map((text) => text[0])
              .join("")}
          </Avatar>
        )}
      </ActionIcon>
    </Flex>
  );
};

export const Discovery = () => {
  const [selectedArea, setSelectedArea] = React.useState(null);
  const page = React.useRef(null);
  const history = useHistory();

  const {
    data: discoveryLocationQueryResult,
    isLoading: isLoadingDiscoveryLocationQuery,
  } = useDiscoveryLocationQuery(
    {
      input: {
        lat: selectedArea?.latitude,
        lng: selectedArea?.longitude,
      },
    },
    {
      enabled: !!selectedArea,
      select: ({ discoveryLocation }) => discoveryLocation,
    },
  );

  return (
    <AppPage forwardRef={page} title="Odkrywaj">
      <IonContent fullscreen>
        <IonHeader
          style={{ paddingTop: "var(--ion-safe-area-top)" }}
          className="mt-2 flex justify-between px-2 items-center"
        >
          <img
            src={require("../../../../public/apple-touch-icon.png")}
            alt="Zozia"
            width={34}
            height={34}
          />
          <DiscoveryToolbar onAreaSelect={setSelectedArea} />
          <Profile />
        </IonHeader>
        <Searchbar
          onClick={() => {
            history.push("/tabs/discovery/search");
          }}
        />
        {/* <div className="px-2 pt-2">
          <ErrorBoundary FallbackComponent={Error}>
            <Reels2>
              <Stories />
            </Reels2>
          </ErrorBoundary>
        </div> */}
        <ErrorBoundary FallbackComponent={Error}>
          {isLoadingDiscoveryLocationQuery ? (
            <Stack className="mt-4">
              {range(3).map((id) => (
                <Stack key={id} gap={4}>
                  <Flex align="center" className="justify-between px-4 pb-2">
                    <Title order={3} size="3" className="mt-0 font-extrabold">
                      <Skeleton
                        style={{ height: 25, width: 250 }}
                        animate={false}
                      />
                    </Title>
                  </Flex>
                  <Snap className="space-x-3">
                    {range(3).map((id) => (
                      <SnapItem2 key={id} className="px-2">
                        <DiscoveryLocationCard loading />
                      </SnapItem2>
                    ))}
                  </Snap>
                </Stack>
              ))}
            </Stack>
          ) : (
            <Stack className="mt-4 mb-12">
              {discoveryLocationQueryResult.categories?.map((discovery) => (
                <Stack key={discovery.id} gap={4}>
                  <Flex className="justify-between px-4 pb-2">
                    <Title order={3} size="3" className="mt-0 font-extrabold">
                      {discovery.name}
                    </Title>
                    <ActionIcon variant="transparent" color="black">
                      <IconChevronRight />
                    </ActionIcon>
                  </Flex>
                  <Snap className="space-x-3">
                    {discovery?.locations.map((location) => (
                      <SnapItem2 key={location.id} className="px-2">
                        <DiscoveryLocationCard location={location} />
                      </SnapItem2>
                    ))}
                  </Snap>
                </Stack>
              ))}
            </Stack>
          )}
        </ErrorBoundary>
      </IonContent>
    </AppPage>
  );
};
