import { type CartItem, type CartWrapper } from "./types";

type AddItem = { type: "ADD_ITEM"; payload: CartItem };
type RemoveItem = {
  type: "REMOVE_ITEM";
  payload: { productId: string; locationId: string };
};
type HydrateCart = { type: "HYDRATE_CART"; payload: CartWrapper };
type HydrateCarts = { type: "HYDRATE_CARTS"; payload: CartWrapper[] };
type ToggleCartButton = { type: "TOGGLE_CART_BUTTON" };
type OpenCartButton = { type: "OPEN_CART_BUTTON" };
type CloseCartButton = { type: "CLOSE_CART_BUTTON" };
type ClearCart = { type: "CLEAR_CART" };
type UpdateLocation = { type: "UPDATE_LOCATION"; payload: string };
type SetCartAsActive = { type: "SET_CART_AS_ACTIVE"; payload: string };
type OptimisticIncreaseItem = {
  type: "OPTIMISTIC_INCREASE_ITEM";
  payload: string;
};
type OptimisticDecreaseItem = {
  type: "OPTIMISTIC_DECREASE_ITEM";
  payload: string;
};

type CartAction =
  | AddItem
  | RemoveItem
  | HydrateCart
  | ToggleCartButton
  | OpenCartButton
  | CloseCartButton
  | ClearCart
  | HydrateCarts
  | UpdateLocation
  | OptimisticIncreaseItem
  | OptimisticDecreaseItem
  | UpdateLocation
  | SetCartAsActive;

type CartState = {
  showCartButton: boolean;
  locationId?: string;
  cart: CartWrapper;
  carts: CartWrapper[];
};

export const initialState = {
  showCartButton: false,
  locationId: undefined,
  cart: {},
  carts: [],
};

export const reducer = (
  state: CartState = initialState,
  action: CartAction,
): CartState => {
  switch (action.type) {
    case "OPTIMISTIC_DECREASE_ITEM": {
      const currentCart = state.cart;
      const product = currentCart.items.find(({ id }) => id === action.payload);
      const newQuantity = product.quantity - 1;

      const newLocationCart = {
        ...currentCart,
        items: currentCart.items.map((item) => {
          if (item.id === action.payload) {
            return {
              ...item,
              quantity: newQuantity,
            };
          }
          return item;
        }),
      };

      return {
        ...state,
        cart: newLocationCart,
      };
    }
    case "OPTIMISTIC_INCREASE_ITEM": {
      const currentCart = state.cart;
      const product = currentCart.items.find(({ id }) => id === action.payload);
      const newQuantity = product.quantity + 1;

      const newLocationCart = {
        ...currentCart,
        items: currentCart.items.map((item) => {
          if (item.id === action.payload) {
            return {
              ...item,
              quantity: newQuantity,
            };
          }
          return item;
        }),
      };

      return {
        ...state,
        cart: newLocationCart,
      };
    }
    case "CLEAR_CART": {
      return initialState;
    }
    case "SET_CART_AS_ACTIVE": {
      const cart = state.carts.find((cart) => cart.id === action.payload);
      const rest = state.carts.filter((cart) => cart.id !== action.payload);
      return {
        ...state,
        carts: [cart, ...rest],
        cart: cart,
      };
    }
    case "UPDATE_LOCATION": {
      return {
        ...state,
        locationId: action.payload,
        cart:
          state.carts.find((cart) => cart.location.id === action.payload) ?? {},
      };
    }
    case "OPEN_CART_BUTTON": {
      return {
        ...state,
        showCartButton: true,
      };
    }
    case "CLOSE_CART_BUTTON": {
      return {
        ...state,
        showCartButton: false,
      };
    }
    case "TOGGLE_CART_BUTTON": {
      return {
        ...state,
        showCartButton: !state.showCartButton,
      };
    }
    case "HYDRATE_CART": {
      return {
        ...state,
        cart: action.payload,
      };
    }
    case "HYDRATE_CARTS": {
      return {
        ...state,
        carts: action.payload,
        cart:
          action.payload.find(
            (cart) => cart.location.id === state.locationId,
          ) ?? {},
      };
    }
    case "REMOVE_ITEM": {
      const locationCart = state.cart[action.payload.locationId];
      const product = locationCart.items[action.payload.productId];
      const newQuantity = product.product.quantity - 1;

      if (newQuantity === 0) {
        const newItems = { ...locationCart.items };
        delete newItems[action.payload.productId];

        return {
          ...state,
          cart: {
            ...state.cart,
            [action.payload.locationId]: {
              ...locationCart,
              items: newItems,
            },
          },
        };
      }

      const newProduct = {
        ...product.product,
        quantity: newQuantity,
      };

      const newLocationCart = {
        ...locationCart,
        items: {
          ...locationCart.items,
          [action.payload.productId]: {
            ...product,
            product: newProduct,
          },
        },
      };

      return {
        ...state,
        cart: {
          ...state.cart,
          [action.payload.locationId]: newLocationCart,
        },
      };
    }
    case "ADD_ITEM": {
      return {
        ...state,
        cart: {
          ...state.cart,
          [action.payload.location.id]: {
            ...state.cart[action.payload.location.id],
            location: action.payload.location,
            items: {
              ...state.cart[action.payload.location.id]?.items,
              [action.payload.product.id]: {
                location: action.payload.location,
                product: {
                  ...action.payload.product,
                  quantity: action.payload.product.quantity,
                },
              },
            },
          },
        },
      };
    }
    default:
      return state;
  }
};
