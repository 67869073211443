import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonSpinner,
  useIonToast,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import {
  Button,
  Group,
  Popover,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  IconBell,
  IconCreditCard,
  IconFileText,
  IconLicense,
  IconLogout,
  IconMail,
  IconMoped,
  IconUser,
} from "@tabler/icons-react";
import { useTranslation } from "@zozia/react-i18n";
import { EmailComposer } from "capacitor-email-composer";
import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { useCurrentTabViewManager } from "../../../CurrentTabViewManager";
import { AppPage } from "../../../components/AppPage";
import { PageContextProvider } from "../../../components/PageContext";
import { RouteBackModal } from "../../../components/RouteBackModal";
import { useFetchData } from "../../../lib/capacitor-fetcher";
import { useCart } from "../../cart/CartContext";
import { ModalAsRoute } from "../../discovery/components/ModalAsRoute";
import {
  useAddUserBillAddressMutation,
  useMyAddressesQuery,
} from "../../graphql/graphql";
import { RecentOrders } from "../../orders";
import { Section } from "../components/Section";
import { useUser } from "../useUser";
import { logout } from "../utils/auth";
import { SetDeliveryAddress } from "./adresses/SetDeliveryAddress/SetDeliveryAddress";
import { SetInvoiceAddress } from "./adresses/SetInvoiceAddress";
import { useManagePaymentMethods } from "./useManagePaymentMethods";

const SeasonalEmoji = () => {
  const currentUserMonth = new Date().getMonth();
  let emoji = "🌸";
  let text = "Odkryj świeżość wiosennych dni";

  if ([8, 9, 10].includes(currentUserMonth)) {
    emoji = "🍂";
    text = "Ciesz się kolorowymi liśćmi i wyjątkową atmosferą";
  }
  if ([5, 6, 7].includes(currentUserMonth)) {
    emoji = "☀️";
    text = "Słońce, plaża, relaks – czas na letnią przygodę!";
  }
  if ([11, 0, 1].includes(currentUserMonth)) {
    const isBeforeChristmas =
      new Date().getDate() <= 25 && currentUserMonth === 11;
    emoji = "❄️";
    text = isBeforeChristmas
      ? "Magia śniegu i świąt tuż za rogiem"
      : "Śnieżna aura nadchodzi";
  }

  return (
    <Popover width={200} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <span className="ml-1">{emoji}</span>
      </Popover.Target>
      <Popover.Dropdown>
        <Text size="xs">{text}</Text>
      </Popover.Dropdown>
    </Popover>
  );
};

export const ProfilePage = () => {
  const match = useRouteMatch();
  const [user] = useUser();
  const pageRef = React.useRef(null);
  const { setIsTabsDisabled, setNavigatingToTab } = useCurrentTabViewManager();
  const { openPaymentMethodsModal } = useManagePaymentMethods();
  const history = useHistory();
  const fetcher = useFetchData("", {
    url: `${process.env.DATA_SERVICE_REST.slice()}/user/logout`,
  });
  const [present] = useIonToast();
  const [logoutLoading, setLogoutLoading] = React.useState(false);
  const { t } = useTranslation();

  const presentToast = (
    message: string,
    position: "top" | "middle" | "bottom",
  ) => {
    present({
      message: message,
      duration: 1500,
      position: position,
    });
  };

  useIonViewWillEnter(() => {
    setIsTabsDisabled(true);
    setNavigatingToTab("profile");
  }, []);

  useIonViewDidEnter(() => {
    setIsTabsDisabled(false);
    setNavigatingToTab(null);
  }, []);

  const handleLogout = async () => {
    setLogoutLoading(true);
    try {
      await fetcher({});
      await logout();
    } catch (e) {
      presentToast(e?.message || "Nie udało się wylogować.", "bottom");
    } finally {
      setLogoutLoading(false);
      presentToast("Wylogowano", "top");

      history.push("/tabs/login");
    }
  };
  return (
    <AppPage forwardRef={pageRef} title="Profil">
      <IonContent className="ion-padding" fullscreen>
        <div
          style={{ marginTop: "var(--ion-safe-area-top)", marginBottom: 36 }}
        >
          {user ? (
            <Title>
              {t("auth.account.page.currentUser", { username: user.givenName })}
              <SeasonalEmoji />
            </Title>
          ) : null}
          <RecentOrders />
          <Section title="Adresy" inset>
            <IonList>
              <IonItem
                className="my-2"
                routerLink={`${match.url}/deliveryAddress`}
              >
                <IconMoped className="mr-2" />
                <IonLabel>Adres dostawy</IonLabel>
              </IonItem>
              <IonItem className="my-2" routerLink={`${match.url}/billAddress`}>
                <IconFileText className="mr-2" />
                <IonLabel>Adres do rachunku</IonLabel>
              </IonItem>
              <IonItem
                className="my-2"
                routerLink={`${match.url}/invoiceAddress`}
              >
                <IconFileText className="mr-2" />
                <IonLabel>Adres do faktury</IonLabel>
              </IonItem>
            </IonList>
          </Section>
          <Section title="Ustawienia" inset>
            <IonList>
              <IonItem routerLink="/tabs/settings/account" className="my-2">
                <IconUser className="mr-2" />
                <IonLabel>Konto</IonLabel>
              </IonItem>
              {/* <IonItem
                className="my-2"
                onClick={() => {
                  openPaymentMethodsModal();
                }}
              >
                <IconCreditCard className="mr-2" />
                <IonLabel>Metody płatności</IonLabel>
              </IonItem> */}
              {/* <IonItem
                routerLink="/tabs/settings/notifications"
                className="my-2"
              >
                <IconBell className="mr-2" />
                <IonLabel>Powiadomienia</IonLabel>
              </IonItem> */}
              <IonItem button onClick={handleLogout}>
                <IconLogout className="mr-2" />
                <IonLabel>Wyloguj</IonLabel>
                {logoutLoading ? <IonSpinner></IonSpinner> : null}
              </IonItem>
            </IonList>
          </Section>
          <Section title="Pomoc" inset>
            <IonList>
              <IonItem className="my-2">
                <IconFileText className="mr-2" />
                <IonLabel>Odpowiedzi na pytania</IonLabel>
              </IonItem>
              <IonItem
                className="my-2"
                onClick={async () => {
                  const encodedSubject = encodeURIComponent(
                    "Pytanie z aplikacji Zozia",
                  );

                  try {
                    const { hasAccount } = await EmailComposer.hasAccount();

                    if (!hasAccount) {
                      window.open(
                        `mailto:kontakt@zozia.app?subject=${encodedSubject}`,
                      );
                    } else {
                      EmailComposer.open({
                        to: ["kontakt@zozia.app"],
                        subject: "Pytanie z aplikacji Zozia",
                      });
                    }
                  } catch (e) {
                    window.open(
                      `mailto:kontakt@zozia.app?subject=${encodedSubject}`,
                    );
                  }
                }}
              >
                <IconMail className="mr-2" />
                <IonLabel>Kontakt</IonLabel>
              </IonItem>
              <IonItem
                className="my-2"
                onClick={() => {
                  window.open("https://zozia.app/legal-terms");
                }}
              >
                <IconLicense className="mr-2" />
                <IonLabel>Regulamin</IonLabel>
              </IonItem>
              <IonItem
                className="my-2"
                onClick={() => {
                  window.open("https://zozia.app/privacy-policy");
                }}
              >
                <IconLicense className="mr-2" />
                <IonLabel>Polityka prywatności</IonLabel>
              </IonItem>
            </IonList>
          </Section>
        </div>
      </IonContent>
      <PageContextProvider>
        <ModalAsRoute
          prevMatch={match}
          routes={{
            [`${match.url}/deliveryAddress`]: SetDeliveryAddress,
            [`${match.url}/billAddress`]: SetBillAddress,
            [`${match.url}/invoiceAddress`]: SetInvoiceAddress,
          }}
          presentingElement={pageRef.current}
        />
      </PageContextProvider>
    </AppPage>
  );
};

export const SetBillAddress = ({
  prevMatch,
  opened,
  open,
  removeNestedOutlet,
}) => {
  const { toggleCartButton } = useCart();
  const { mutateAsync: mutateAsyncSetBillAddress, isLoading } =
    useAddUserBillAddressMutation();

  const routeModalRef = React.useRef<HTMLIonModalElement>(null);

  const { data } = useMyAddressesQuery({
    type: "BillAddress",
  });

  React.useEffect(() => {
    if (data && data.myAddresses.length > 0) {
      const values = data.myAddresses[0].address;
      form.setValues(values);
      form.resetDirty(values);
    }
  }, [data]);

  React.useEffect(() => {
    toggleCartButton();
    return () => {
      toggleCartButton();
    };
  }, []);

  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      street: "",
      streetNumber: "",
      apartmentNumber: "",
      zipNumber: "",
      city: "",
      email: "",
      country: "Polska",
    },
  });

  const handleFormSubmit = async (values) => {
    await mutateAsyncSetBillAddress({
      input: values,
    });
    form.resetDirty();
    routeModalRef.current.close();
  };

  return (
    <RouteBackModal
      ref={routeModalRef}
      clonseButton
      prevMatch={prevMatch}
      title="Adres do rachunku"
      opened={opened}
      open={open}
      removeNestedOutlet={removeNestedOutlet}
      contentClassName="ion-padding"
    >
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <Stack>
          <TextInput
            required
            label="Imię"
            {...form.getInputProps("firstName")}
          />
          <TextInput
            required
            label="Nazwisko"
            {...form.getInputProps("lastName")}
          />
          <TextInput label="Ulica" {...form.getInputProps("street")} />
          <Group grow wrap="nowrap">
            <TextInput
              label="Numer budynku"
              required
              placeholder="Np. 12"
              {...form.getInputProps("streetNumber")}
            />
            <TextInput
              label="Numer mieszkania"
              placeholder="Np. 2"
              {...form.getInputProps("apartmentNumber")}
            />
          </Group>
          <Group grow wrap="nowrap">
            <TextInput
              required
              label="Kod pocztowy"
              placeholder="Np. 12"
              {...form.getInputProps("zipNumber")}
            />
            <TextInput
              required
              label="Miasto"
              placeholder="Np. 2"
              {...form.getInputProps("city")}
            />
          </Group>
          <TextInput
            required
            label="E-mail do rachunku"
            {...form.getInputProps("email")}
          />
        </Stack>
        <Button
          type="submit"
          fullWidth
          mt={20}
          disabled={!form.isDirty()}
          loading={isLoading}
        >
          Zapisz
        </Button>
      </form>
    </RouteBackModal>
  );
};
