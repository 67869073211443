import { useIonRouter } from "@ionic/react";
import { Avatar, Group } from "@mantine/core";
import { cropImageTo } from "@zozia/ui";
import { motion } from "framer-motion";
import * as React from "react";

import { useCart } from "../../CartContext";
import css from "./AvatarGroup.module.css";

const cropImageToDesiredSize = cropImageTo(0, 34);

export const AvatarGroup = () => {
  const router = useIonRouter();
  const ref = React.useRef();
  const { carts, cart: activeCart, dispatch } = useCart();

  const firstVisibleCarts = carts.slice(0, 2);

  const currentSelectedIndex = firstVisibleCarts.findIndex(
    (cart) => cart.id === activeCart.id,
  );

  console.log({currentSelectedIndex})

  React.useLayoutEffect(() => {
    if (ref.current) {
      if (currentSelectedIndex === -1) {
        ref.current.style.left = "0px";
        router.push(`/tabs/discovery/locations/${carts[0].location.id}`);
      } else {
        ref.current.style.left = (34 + 4) * currentSelectedIndex + "px";
      }
    }
  }, [ref, currentSelectedIndex]);

  return (
    <Group className="relative" gap={4}>
      {firstVisibleCarts.map((visibleCart) => (
        <Avatar
          size="34px"
          key={visibleCart.id}
          className="mb-1"
          onClick={() => {
            // dispatch({ type: "SET_CART_AS_ACTIVE", payload: visibleCart.id });
            router.push(`/tabs/discovery/locations/${visibleCart.location.id}`);
          }}
          src={cropImageToDesiredSize(
            visibleCart.location.images.find((img) => img.size === "logo")?.url,
          )}
        />
      ))}
      <motion.div layout className={css.highlight} ref={ref} />
    </Group>
  );
};
