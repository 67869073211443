import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { IonContent, IonModal, useIonRouter } from "@ionic/react";
import { ActionIcon, Button, Group, Stack, Text, Title } from "@mantine/core";
import { useClickOutside, useDisclosure } from "@mantine/hooks";
import { IconExclamationCircle, IconPlus } from "@tabler/icons-react";
import { motion } from "framer-motion";
import * as React from "react";

import { cn } from "../../../lib/mergeTailwindClasses";
import { DecreaseQuantity } from "../../cart/modals/ActiveCartModal/DecreaseQuantity";
import { useUser } from "../../session/useUser";

type ProductQuantityActions = {
  initialItemQuantity: number;
  className?: string;
  onIncrease: (quantity: number) => void;
  onDecrease: (quantity: number) => void;
};

export const ProductDetailsQuantityActions = React.memo(
  ({
    initialItemQuantity = 0,
    className,
    onIncrease,
    onDecrease,
  }: ProductQuantityActions) => {
    const [active, setActive] = React.useState(false);
    const ref = useClickOutside(() => setActive(false));
    const [itemQuantity, setItemQuantity] = React.useState(initialItemQuantity);
    const [opened, { open, close }] = useDisclosure();
    const router = useIonRouter();
    const [user] = useUser();

    React.useEffect(() => {
      setItemQuantity(initialItemQuantity);
    }, [initialItemQuantity]);

    return (
      <>
        <div className={cn("relative", className)} ref={ref}>
          <motion.div
            className="absolute overflow-hidden border border-solid border-gray-300 whitespace-nowrap bg-white rounded-md"
            onClick={async (event) => {
              event.preventDefault();

              await Haptics.impact({
                style: ImpactStyle.Light,
              });

              if (!user) {
                open();
                return;
              }
              setActive((prev) => !prev);
            }}
            initial={{
              borderRadius: "4px",
              width: 65,
            }}
            transition={{
              duration: 0.2,
            }}
            animate={{
              width: active ? 170 : 65,
              left: active ? -120 : 0,
              borderRadius: active ? "20px" : "4px",
            }}
            style={{
              boxShadow: active ? "rgba(0, 0, 0, 0.2) 0px 0px 20px 0" : "none",
            }}
            layout
          >
            <Group
              justify="start"
              align="center"
              className="p-1"
              grow
              wrap="nowrap"
            >
              {active ? (
                <DecreaseQuantity
                  quantity={itemQuantity}
                  onDecrease={(event) => {
                    event.stopPropagation();

                    setItemQuantity((prev) => {
                      const nextQuantity = prev - 1;

                      if (nextQuantity <= 0) {
                        onDecrease(0);
                        setActive(false);
                        return 0;
                      }

                      onDecrease(nextQuantity);
                      return nextQuantity;
                    });

                    Haptics.impact({
                      style: ImpactStyle.Light,
                    });
                  }}
                />
              ) : null}
              <div
                className="table text-center"
                onClick={(e) => (active ? e.stopPropagation() : null)}
              >
                {itemQuantity}
              </div>
              {active ? (
                <ActionIcon
                  size="xs"
                  variant="transparent"
                  c="black"
                  onClick={(event) => {
                    event.stopPropagation();

                    setItemQuantity((prev) => {
                      const nextQuantity = prev + 1;

                      onIncrease(nextQuantity);

                      return nextQuantity;
                    });
                    Haptics.impact({
                      style: ImpactStyle.Light,
                    });
                  }}
                >
                  <IconPlus />
                </ActionIcon>
              ) : null}
            </Group>
          </motion.div>
        </div>
        <IonModal
          isOpen={opened}
          initialBreakpoint={0.5}
          breakpoints={[0, 0.5]}
          onIonModalDidDismiss={close}
        >
          <IonContent>
            <Stack align="center" className="mt-8" gap={0}>
              <IconExclamationCircle size="65" strokeWidth={1} />
              <Title order={3}>Logowanie wymagane</Title>
              <Text className="text-center" c="gray">
                Dodanie produktów do koszyka wymaga konta uzytkownika
              </Text>

              <Stack align="stretch" className="mt-4 w-11/12">
                <Button
                  fullWidth
                  radius="lg"
                  style={{
                    fontWeight: 400,
                  }}
                  onClick={async () => {
                    close();
                    router.push(
                      `/tabs/login?returnUrl=${router.routeInfo.pathname}`,
                    );
                  }}
                >
                  Logowanie
                </Button>
                <Button
                  fullWidth
                  radius="lg"
                  style={{
                    fontWeight: 400,
                  }}
                  onClick={close}
                >
                  Anuluj
                </Button>
              </Stack>
            </Stack>
          </IonContent>
        </IonModal>
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.initialItemQuantity === nextProps.initialItemQuantity,
);
