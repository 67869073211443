import { IonContent, IonItem, IonLabel, IonList } from "@ionic/react";
import { LoadingOverlay, Text } from "@mantine/core";
import {
    IconCircleCheck,
  IconHourglassEmpty,
  IconMoodLookDown,
  IconPackage,
  IconQuestionMark,
  IconTruckDelivery,
} from "@tabler/icons-react";
import * as React from "react";
import { TimeAgo } from "@zozia/ui";

import { AppPage } from "../../../components/AppPage";
import {
  useMyOrdersQuery,
} from "../../graphql/graphql";
import { Section } from "../../session/components/Section";

import css from "./OrdersPage.module.css";

export const OrdersPage = () => {
  const { data, isLoading: isLoadingOrders } = useMyOrdersQuery(null);

  return (
    <AppPage title={data && `Zamówienia`}>
      <IonContent className="ion-padding">
        {isLoadingOrders ? (
          <LoadingOverlay visible={isLoadingOrders} />
        ) : (
    <Section title="Ostatnie zamówienia" inset className={css.root}>
      <IonList lines="full">
        {data?.myOrders
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((order) => (
            <IonItem
              button
              key={order.id}
              routerLink={`/tabs/order/${order.id}`}
            >
              {(() => {
                switch (order.status) {
                  case "Ordered":
                  case "Processing":
                  case "Accepted":
                    return <IconHourglassEmpty slot="start" />;
                  case "Delivering":
                    return <IconTruckDelivery slot="start" />;
                  case "Delivered":
                  case "ReadyToPickUp":
                    return <IconPackage slot="start" />;
                  case "Done":
                    return <IconCircleCheck slot="start" />;
                  case "Canceled":
                    return <IconCircleCheck slot="start" />;
                  case "Rejected":
                    return <IconMoodLookDown slot="start" />;
                  case "Disputed":
                    return <IconQuestionMark slot="start" />;
                  default:
                    return null;
                }
              })()}
              <IonLabel>
                {order?.location?.name}
                <Text component="p">
                  <TimeAgo value={order.createdAt} />
                </Text>
              </IonLabel>
            </IonItem>
          ))}
      </IonList>
    </Section>
        )}
      </IonContent>
    </AppPage>
  );
};
