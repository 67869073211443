import { IonItem, IonLabel, IonList, useIonRouter } from "@ionic/react";
import { Button, Card, Text } from "@mantine/core";
import {
  IconCircleCheck,
  IconHourglassEmpty,
  IconMoodLookDown,
  IconPackage,
  IconQuestionMark,
  IconTruckDelivery,
} from "@tabler/icons-react";
import { TimeAgo } from "@zozia/ui";
import * as React from "react";

import { useMyOrdersQuery } from "../../graphql/graphql";
import { Section } from "../../session/components/Section";

export const RecentOrders = () => {
  const { data, refetch } = useMyOrdersQuery(null);
  
  React.useEffect(() => {
    refetch();
  }, []);

  const router = useIonRouter();

  const hasData = data?.myOrders?.length > 0;

  return hasData ? (
    <Section title="Ostatnie zamówienia" inset>
      <IonList lines="full">
        {data?.myOrders
          .sort((a, b) => b.createdAt - a.createdAt)
          .slice(0, 3)
          .map((order) => (
            <IonItem
              button
              key={order.id}
              routerLink={`/tabs/order/${order.id}`}
            >
              {(() => {
                switch (order.status) {
                  case "Ordered":
                  case "Processing":
                  case "Accepted":
                    return <IconHourglassEmpty slot="start" />;
                  case "Delivering":
                    return <IconTruckDelivery slot="start" />;
                  case "Delivered":
                  case "ReadyToPickUp":
                    return <IconPackage slot="start" />;
                  case "Done":
                    return <IconCircleCheck slot="start" />;
                  case "Canceled":
                    return <IconCircleCheck slot="start" />;
                  case "Rejected":
                    return <IconMoodLookDown slot="start" />;
                  case "Disputed":
                    return <IconQuestionMark slot="start" />;
                  default:
                    return null;
                }
              })()}
              <IonLabel>
                {order?.location?.name}
                <Text component="p">
                  <TimeAgo value={order.createdAt} />
                </Text>
              </IonLabel>
            </IonItem>
          ))}
        {data?.myOrders.length > 3 ? (
          <div className="flex justify-center">
            <Button variant="transparent" onClick={() => {
              router.push("/tabs/orders", "back");
            }}>Wszystkie zamówienia</Button>
          </div>
        ) : null}
      </IonList>
    </Section>
  ) : (
    <Section title="Twoje zamówienia" inset="clear">
      <Card className="border-2 border-solid border-gray-600 mt-4">
        <Text fw={600} size="lg">
          Tu znajdziesz swoje zamówienia
        </Text>
        <Text size="sm" className="mt-2">
          Zacznij przeglądać oferty lokalnych dostawców i zrób swoje pierwsze
          zakupy
        </Text>
        <div>
          <Button
            className="mt-2"
            size="xs"
            onClick={() => {
              router.push("/tabs/discovery", "back");
            }}
          >
            Przeglądaj lokalnych dostawców
          </Button>
        </div>
      </Card>
    </Section>
  );
};
