import { Avatar, AvatarGroup, Button, Text } from "@mantine/core";
import { IconShoppingCartFilled } from "@tabler/icons-react";
import { cropImageTo } from "@zozia/ui";
import { motion } from "framer-motion";
import * as React from "react";

import { AutoAlignImage } from "../../../components/AutoAlignImage";
import { formatOrdinals } from "../../../lib/translateCartText";
import { useCart } from "../CartContext";

const cropImageToDesiredSize = cropImageTo(100);

type ShowCartsFloatingButton = {
  onClick: () => void;
};

export const ShowCartsFloatingButton = ({
  onClick,
  ...rest
}: ShowCartsFloatingButton) => {
  const { carts } = useCart();
  return (
    <Button
      size="lg"
      radius="xl"
      onClick={onClick}
      component={motion.div}
      {...rest}
    >
      <AvatarGroup>
        {carts.slice(0, 2).map((cart) => (
          <Avatar key={cart.id}>
            <AutoAlignImage
              src={
                cart.location.images.find((img) => img.size === "logo")?.url
                  ? cropImageToDesiredSize(
                      cart.location.images.find((img) => img.size === "logo")
                        ?.url,
                    )
                  : `https://picsum.photos/38/38?random=${Math.random()}`
              }
              style={{ width: 38, height: 38 }}
            />
          </Avatar>
        ))}
        {carts.length > 2 && (
          <Avatar className="border-none" color="gray" size={38}>
            +{carts.slice(2).length}
          </Avatar>
        )}
      </AvatarGroup>
      <IconShoppingCartFilled size="32" />
      <Text size="sm" fw="bold">
        Zobacz {formatOrdinals(carts.length)}
      </Text>
    </Button>
  );
};
