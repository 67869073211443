import { LiveUpdate } from "@capawesome/capacitor-live-update";

let state = {
  isLiveUpdateLoading: false,
  latestVersion: null,
  currentVersion: null,
  updateReady: false,
  willUpdate: false,
};

let listeners = [];

if (globalThis.__ZOZIA__) {

  globalThis.__ZOZIA__.restart = async () => {
    await LiveUpdate.reset();
    await LiveUpdate.reload();
  }
} else {
  globalThis.__ZOZIA__ = {
    ...globalThis.__ZOZIA__,
    restart: async () => {
      await LiveUpdate.reset();
      await LiveUpdate.reload();
    }
  }
}

export const ready = async () => {
  await LiveUpdate.ready();
}

export async function reload() {
  await LiveUpdate.reload();

  liveUpdateStore.setLiveUpdateReady();
}

export const initializeLiveUpdate = async () => {
  if (!state.willUpdate || state.isLiveUpdateLoading) {
    return;
  }

  state = {
    ...state,
    isLiveUpdateLoading: true,
  };

  emitChange();

  const result = await LiveUpdate.sync({
    channel: "dev"
  });
  
  if (result.nextBundleId) {
    await LiveUpdate.reload();
  }
};

export const liveUpdateStore = {
  async checkForUpdate() {
    const { bundleId: latestBundleId } = await LiveUpdate.fetchLatestBundle({ channel: "dev" });
    liveUpdateStore.setLastestBundleVersion(latestBundleId);

    if (latestBundleId) {
      const { bundleId: currentBundleId } = await LiveUpdate.getCurrentBundle();
      liveUpdateStore.setCurrentVersion(currentBundleId);
      if (latestBundleId !== currentBundleId) {
        state = {
          ...state,
          willUpdate: true,
        }
        emitChange();
      }
    }
  },

  async setLiveUpdateReady() {
    await LiveUpdate.ready();

    state = {
      ...state,
      updateReady: true,
    };

    emitChange();
  },
  setLoading(isLoading: boolean) {
    state = {
      ...state,
      isLiveUpdateLoading: isLoading,
    };
    emitChange();
  },
  setCurrentVersion(currentVersion: string) {
    state = {
      ...state,
      currentVersion,
    };

    emitChange();
  },
  setLastestBundleVersion(latestVersion: string) {
    state = {
      ...state,
      latestVersion: latestVersion,
    };

    emitChange();
  },
  subscribe(listener) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },
  getSnapshot() {
    return state;
  },
};

function emitChange() {
  for (const listener of listeners) {
    listener();
  }
}
