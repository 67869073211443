import * as React from "react";

import { cn } from "../utils/cn";
import { SegmentContext } from "./context";

type SegmentButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  value: any;
};

export const SegmentButton = ({
  children,
  onClick,
  value,
}: SegmentButtonProps) => {
  const ctx = React.useContext(SegmentContext);

  const onClickHandler = () => {
    ctx.onClick(value);
    onClick?.();
  };

  const isActive = ctx.value === value;

  const wrapperClassName = cn("h-full h-8 w-max relative", {
    "font-semibold font-body bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text text-transparent":
      isActive,
  });

  return (
    <li onClick={onClickHandler}>
      <div className={wrapperClassName}>
        {children}
        <div
          style={{
            position: "absolute",
            bottom: 0,
            height: "4px",
            width: "100%",
          }}
          className={`${
            isActive ? "bg-gradient-to-r from-blue-500 to-teal-400" : ""
          }`}
        ></div>
      </div>
    </li>
  );
};
