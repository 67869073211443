import {
  CreatePaymentSheetOption,
  PaymentSheetEventsEnum,
} from "@capacitor-community/stripe";
import { useCapacitorStripe } from "@capacitor-community/stripe/dist/esm/react/provider";
import * as React from "react";

import { useUserAddress } from "../../session/hooks/useUserDeliveryAddress";
import { useCart } from "../CartContext";
import { PayButton } from "./PayButton";
import { useFetchData } from "../../../lib/capacitor-fetcher";
import { CreatePaymentIntentDocument, useCompleteCartProcessMutation } from "../../graphql/graphql";
import { useIonRouter } from "@ionic/react";

const print = (query: string) => query.replace(/(\r\n|\n|\r)/gm, "");

type PayProcessProps = {
  form: any;
  onSuccess?: (data: any) => void;
};
export const PayProcess = ({ form, onSuccess }: PayProcessProps) => {
  const { stripe, isApplePayAvailable } = useCapacitorStripe();
  const { cart, refreshCarts } = useCart();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const router = useIonRouter();

  const { address: myDeliveryAccess, isLoading: isDeliveryAddressLoading } =
    useUserAddress("DeliveryAddress");

  const { mutate: completeProcessAsync } = useCompleteCartProcessMutation({
    onSuccess: async (data) => {
      router.push(`/tabs/order/${data.completeCartProcess.data.orderId}`);
      await onSuccess?.(data);
      refreshCarts();
    },
  });
  
  const fetcher = useFetchData(print(CreatePaymentIntentDocument), {
    url: process.env.DATA_SERVICE,
  });
  
  const pay = async () => {
    const { hasErrors } = form.validate();

    if (hasErrors) {
      return;
    }

    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    let paymentIntentResponse;
    try {
      paymentIntentResponse = await fetcher({
        input: {
          shoppingCartId: cart.id as unknown as string,
        },
      });
    } catch (e) {
      console.error(e);
      setIsProcessing(false);
      return;
    }

    try {

      const shouldPresentAlsoApplePay =
        isApplePayAvailable &&
        (cart.paymentMethod.type === "ApplePay" ||
          cart.paymentMethod.type === "Online");

      let paymentSheetData: CreatePaymentSheetOption = {
        paymentIntentClientSecret: paymentIntentResponse.createPaymentIntent.paymentIntent as unknown as string,
        customerEphemeralKeySecret: paymentIntentResponse.createPaymentIntent.ephemeralKey as unknown as string,
        customerId: paymentIntentResponse.createPaymentIntent.customer as unknown as string,
        returnURL: "com.zozia.app://tabs/discovery",
        merchantDisplayName: "Zozia Marketplace",
        countryCode: "PL",
      };

      if (shouldPresentAlsoApplePay) {
        paymentSheetData = {
          ...paymentSheetData,
          applePayMerchantId: "merchant.com.zozia.app",
          enableApplePay: true,
        };
      }

      await stripe.createPaymentSheet(paymentSheetData);

      const { paymentResult } = await stripe.presentPaymentSheet();

      if (paymentResult === PaymentSheetEventsEnum.Completed) {
        await completeProcessAsync({
          input: {
            cartId: cart.id as unknown as string,
            deliveryAddressId: myDeliveryAccess.id,
          },
        });
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return <PayButton onClick={pay} isLoading={isProcessing} />;
};
