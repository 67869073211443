import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
  NavContext,
} from "@ionic/react";
import { CloseButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import * as React from "react";
import { useHistory } from "react-router-dom";

import { useRedirectQuerySearch } from "../../hooks/useRedirectQuerySearch";

export const RouteBackModal = React.forwardRef(
  (
    {
      children,
      title,
      presentingElement,
      removeNestedOutlet,
      contentClassName,
      nestedRouteComponentProps,
    }: {
      children: any;
      title: any;
      removeNestedOutlet: any;
      presentingElement: any;
      contentClassName?: string;
      nestedRouteComponentProps?: any;
    },
    ref,
  ) => {
    const [isModalOpenedInternal, { close: closeModalInternal }] =
      useDisclosure(true);
    const { redirectUri } = useRedirectQuerySearch();
    const history = useHistory();
    const { routeInfo, ...rest } = React.useContext(NavContext);

    React.useImperativeHandle(ref, () => ({
      close: closeModalInternal,
      redirectBack: () => {
        if (redirectUri) {
          history.push(redirectUri);
        }
      },
    }));

    return (
      <IonModal
        ref={ref}
        isOpen={isModalOpenedInternal}
        backdropDismiss
        onWillDismiss={() => {
          if (redirectUri) {
            history.push(redirectUri);
          }
        }}
        onDidDismiss={() => {
          if (!redirectUri) {
            removeNestedOutlet();
            window.history.replaceState(
              null,
              document.title,
              routeInfo.lastPathname,
            );
            // rest.navigate(routeInfo.lastPathname);
          }
        }}
        presentingElement={presentingElement}
        {...nestedRouteComponentProps}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
              <CloseButton onClick={closeModalInternal} />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent
          {...(contentClassName ? { className: contentClassName } : {})}
        >
          {children}
        </IonContent>
      </IonModal>
    );
  },
);
