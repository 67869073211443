import { ApplePayEventsEnum } from "@capacitor-community/stripe";
import { useCapacitorStripe } from "@capacitor-community/stripe/dist/esm/react/provider";
import { useIonRouter } from "@ionic/react";
import * as React from "react";

import {
  CompleteCartProcessMutation,
  CreatePaymentIntentDocument,
  useCompleteCartProcessMutation,
} from "../../graphql/graphql";
import { useUserAddress } from "../../session/hooks/useUserDeliveryAddress";
import { useCart } from "../CartContext";
import ApplePayButton from "./ApplePayButton";
import { useFetchData } from "../../../lib/capacitor-fetcher";

const print = (query: string) => query.replace(/(\r\n|\n|\r)/gm, "");

type ApplePayProcessProps = {
  onSuccess?: (data: CompleteCartProcessMutation) => void;
  form: any;
};
export const ApplePayProcess = ({ onSuccess, form }: ApplePayProcessProps) => {
  const { stripe } = useCapacitorStripe();
  const { cart, totalSum, refreshCarts } = useCart();
  const router = useIonRouter();
  const [isProcessing, setIsProcessing] = React.useState(false);

  const { address: myDeliveryAccess, isLoading: isDeliveryAddressLoading } =
    useUserAddress("DeliveryAddress");

  const { mutateAsync: completeProcessAsync } = useCompleteCartProcessMutation({
    onSuccess: async (data) => {
      router.push(`/tabs/order/${data.completeCartProcess.data.orderId}`);
      await onSuccess?.(data);
      refreshCarts();
    },
  });

  const fetcher = useFetchData(print(CreatePaymentIntentDocument), {
    url: process.env.DATA_SERVICE,
  });


  const pay = async () => {
    const { hasErrors } = form.validate();

    if (hasErrors) {
      return;
    }

    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    let paymentIntentResponse;
    try {
      paymentIntentResponse = await fetcher({
        input: {
          shoppingCartId: cart.id as unknown as string,
        },
      });
    } catch (e) {
      console.error(e);
      setIsProcessing(false);
      return;
    }

    try {
      await stripe.createApplePay({
        merchantIdentifier: "merchant.com.zozia.app",
        paymentIntentClientSecret: paymentIntentResponse.createPaymentIntent.paymentIntent as unknown as string,
        paymentSummaryItems: [
          {
            label: `Płatność za zakupu w ${cart.location.name}`,
            amount: totalSum.toNumber(),
          },
        ],
        countryCode: "PL",
        currency: totalSum.currency,
      });

      const { paymentResult } = await stripe.presentApplePay();

      if (paymentResult === ApplePayEventsEnum.Completed) {
        await completeProcessAsync({
          input: {
            cartId: cart.id as unknown as string,
            deliveryAddressId: myDeliveryAccess.id,
          },
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsProcessing(false);
    }
  };

  return <ApplePayButton type="check-out" locale="pl-PL" onClick={pay} />;
};
