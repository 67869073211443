import { IonContent } from "@ionic/react";
import { Button, Group, Stack, Text, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconMapPin } from "@tabler/icons-react";
import { tryToGetMostAccurateAddress } from "@zozia/address";
import { useObservableState } from "observable-hooks";
import * as React from "react";
import { type match } from "react-router-dom";

import { usePageContext } from "../../../../../components/PageContext";
import { RouteBackModal } from "../../../../../components/RouteBackModal";
import { useStateQuerySearch } from "../../../../../hooks/useStateQuerySearch";
import { location$ } from "../../../../geolocation/epics";
import { useUpsertUserDeliveryAddressMutation } from "../../../../graphql/graphql";
import { useUserAddress } from "../../../hooks/useUserDeliveryAddress";

type DeliveryAddressAdditionalInformationModalProps = {
  prevMatch: match;
  opened: boolean;
  open: () => void;
  removeNestedOutlet: () => void;
};
export const DeliveryAddressAdditionalInformationModal = ({
  prevMatch,
  opened,
  removeNestedOutlet,
  open,
  nestedRouteComponentProps
}: DeliveryAddressAdditionalInformationModalProps) => {
  const { data } = usePageContext();
  const { userForcedPosition } = data;
  const currentLocation = useObservableState(location$, null);
  const modalRef = React.useRef<HTMLIonModalElement>(null);
  const { state } = useStateQuerySearch();
  const [address, setAddress] = React.useState(null);

  const shouldLoadUserAddress = state === "useSavedAddress";

  const { address: deliveryAddress } = useUserAddress("DeliveryAddress");

  console.log({nestedRouteComponentProps})

  React.useEffect(() => {
    if (!shouldLoadUserAddress) {
      if (currentLocation || userForcedPosition) {
        const latitude = userForcedPosition?.lat || currentLocation.latitude;
        const longitude = userForcedPosition?.lng || currentLocation.longitude;
        fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`,
        )
          .then((res) => res.json())
          .then((data) => {
            const { address: posibleAddress } = data;

            const accurateAddress = tryToGetMostAccurateAddress(posibleAddress);
            setAddress(accurateAddress);
          });
      }
    }
    if (shouldLoadUserAddress && deliveryAddress) {
      setAddress(tryToGetMostAccurateAddress(deliveryAddress));
    }
  }, [
    currentLocation,
    userForcedPosition,
    shouldLoadUserAddress,
    deliveryAddress,
  ]);

  React.useEffect(() => {
    if (userForcedPosition && address) {
      const formValues = prepareValuesForSubmit({
        address,
        userForcedPosition,
        currentLocation,
      });
      form.setValues(formValues);
    } else if (deliveryAddress) {
      form.setValues(deliveryAddress);
    }
  }, [deliveryAddress, address]);

  const form = useForm({
    initialValues: {
      apartmentNumber: "",
      floor: "",
      additionalInformation: "",
    },
  });

  const { mutate: mutateSetDeliveryAddress, isLoading } =
    useUpsertUserDeliveryAddressMutation({
      onSuccess: async () => {
        await modalRef.current.close();
        await nestedRouteComponentProps.modal.current.close();
      },
    });

  const handleFormSubmit = async (values) => {
    const formValues = prepareValuesForSubmit({
      address,
      userForcedPosition,
      currentLocation,
    });

    mutateSetDeliveryAddress({
      input: {
        ...values,
        ...formValues,
      },
    });

  };

  return (
    <RouteBackModal
      prevMatch={prevMatch}
      opened={opened}
      open={open}
      ref={modalRef}
      removeNestedOutlet={removeNestedOutlet}
      title="Dodatkowe informacje"
    >
      <IonContent className="ion-padding">
        <Stack gap={2}>
          <Group align="start">
            <IconMapPin className="mt-1" />
            <Stack gap={1}>
              <Text fw={600} size="lg">
                {address?.primary}
              </Text>
              <Text>{address?.secondary}</Text>
            </Stack>
          </Group>
        </Stack>
        <form onSubmit={form.onSubmit(handleFormSubmit)}>
          <Stack>
            <Group grow wrap="nowrap">
              <TextInput
                label="Numer mieszkania"
                placeholder="Np. 12"
                {...form.getInputProps("apartmentNumber")}
              />
              <TextInput
                label="Piętro"
                placeholder="Np. 2"
                {...form.getInputProps("floor")}
              />
            </Group>
            <Textarea
              label="Dodatkowe informacje dla dostawcy"
              placeholder="Podaj wazne informacje, np. kod do klatki lub gdzie kurkier ma zostawić Twoje zakupy"
              {...form.getInputProps("additionalInformation")}
            />
          </Stack>
          <Group wrap="nowrap" className="mt-4">
            <Button type="submit" variant="light" fullWidth>
              Pomiń
            </Button>
            <Button type="submit" fullWidth loading={isLoading}>
              Zapisz
            </Button>
          </Group>
        </form>
      </IonContent>
    </RouteBackModal>
  );
};

function prepareValuesForSubmit({
  address,
  userForcedPosition,
  currentLocation,
}) {
  const splitedAddress = address.primary.split(" ");
  const [posibleHouseNumber] = splitedAddress.reverse();
  const street = posibleHouseNumber
    ? address.primary.split(" ").slice(0, splitedAddress.length - 1)
    : splitedAddress;
  const [zipNumber, ...city] = address.secondary.split(" ");
  const formValues = {
    zipNumber,
    country: "Polska",
    street: street.join(" "),
    streetNumber: posibleHouseNumber ?? "",
    city: city.join(" "),
    latitude: userForcedPosition?.lat || currentLocation.latitude,
    longitude: userForcedPosition?.lng || currentLocation.longitude,
  };

  return formValues;
}
